<div class="inner-page">
  <div class="row">
    <div class="col-md-11">
      <h2>Double Plan Benefit</h2>
      <table class="table table-bordered table-hover table-sm">
        <thead class="thead-light">
          <tr>
            <th colspan="10">
              Double Plan Benefit : Schedule of Maturity value (Compounded at
              Quarterly rests)
            </th>
          </tr>
          <tr>
            <th rowspan="2" class="align-center">
              Amount Invested<br />
              Rs. P.
            </th>
            <th class="align-center">Interest @ 7% p.a</th>
            <th class="align-center">Interest @ 7.50% p.a</th>
            <th colspan="7" class="align-center">Interest @ 7% p.a</th>
             
          </tr>
          <tr>
            <th>12 Months</th>
            <th>401 Days</th>
            <th>24 Months</th>
            <th>36 Months</th>
            <th>48 Months</th>
            <th>60 Months</th>
            <th>72 Months</th>
            <th>84 Months</th>
            <th>120 Months</th>
            
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>10,000</td>
            <td>10,719</td>
            <td>10,853</td>
            <td>11,489</td>
            <td>12,314</td>
            <td>13,199</td>
            <td>14,148</td>
            <td>15,164</td>
            <td>16,254</td>
            <td>20,016</td>
          </tr>

          <tr>
            <td>15,000</td>
            <td>16,078</td>
            <td>16,279</td>
            <td>17,233</td>
            <td>18,472</td>
            <td>19,799</td>
            <td>21,222</td>
            <td>22,747</td>
            <td>24,381</td>
            <td>30,024</td>
          </tr>

          <tr>
            <td>20,000</td>
            <td>21,437</td>
            <td>21,705</td>
            <td>22,978</td>
            <td>24,629</td>
            <td>26,399</td>
            <td>28,296</td>
            <td>30,329</td>
            <td>32,508</td>
            <td>40,032</td>
          </tr>

          <tr>
            <td>50,000</td>
            <td>53,593</td>
            <td>54,263</td>
            <td>57,444</td>
            <td>62,572</td>
            <td>65,996</td>
            <td>70,739</td>
            <td>75,822</td>
            <td>81,271</td>
            <td>1,00,080</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
