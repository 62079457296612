import { Component } from '@angular/core';

@Component({
  selector: 'app-double-benefit-plan',
  templateUrl: './double-benefit-plan-widget.component.html',
  styleUrls: ['./double-benefit-plan-widget.component.scss']
})
export class DoubleBenefitPlanWidgetComponent {

}
