import { Component } from '@angular/core';


declare var Email;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {


  ToEmail = 'jyothisoc@gmail.com';

  mailsent=false;

  sendMail(form:any) {

    console.log(form.value);

    if (!form.valid) {
      alert("Please enter a valid email id");
      return
    }

    // Email.send({
    //   Host: "smtp.sendgrid.net",
    //   Username: "apikey",
    //   Password: "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",
    //   To: this.ToEmail,
    //   From: form.value.email,
    //   Subject: "Enquiry from the Web",
    //   Body:
    //     "Name : " + form.value.name +
    //     "<br>Phone Number: " + form.value.phone +
    //     "<br>Email ID: " + form.value.email +
    //     "<br>Message: " + form.value.message
    // }).then(Message => {
    //   console.log(Message)
    //   if (Message=="OK") {
    //     this.mailsent=true;
    //   }
    // });

    // Email.send({
    //   Host: "smtp.sendgrid.net",
    //   Username: "apikey",
    //   Password: "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",
    //   To: form.value.email,
    //   From: 'jyothisoc@gmail.com',
    //   Subject: "Thank you for the Enquiry",
    //   Body: "Dear " + form.value.name + " <br> " +
    //     "Thank you for contacting us for your requirements. <br><br>" +
    //     "We will get in touch with you shortly. You can also call us on (+91) 9702210786 <br><br>" +
    //     "Thanking you, <br><br>" +
    //     "<strong>Shri Girish B. Salian</strong><br> " +
    //     "For <strong>Jyothi Co-Op. Credit Society Ltd.</strong>"
    // }).then(Message => {
    //   console.log(Message)
    // });

  }

}
