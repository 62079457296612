<div class="container-fluid carouselWidget">

  <ngb-carousel>

    <ng-template ngbSlide>
      <div class="slide slide1">
         <!--<a routerLink="/home" > 
          <img src="assets\carousal\deposits-accounts-in-jyothi-co-operative-credit-society.jpg">
        </a> -->
      </div>
    </ng-template>

    <ng-template ngbSlide>
      <div class="slide slide2">
       <!--  <a routerLink="/home">
          <img src="assets\carousal\manage-your-deposits-with-us.jpg"> 
        </a>-->
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="slide slide3">
        <!--  <a routerLink="/home">
         <img src="assets\carousal\quick-loan-approval-and-disbursement.jpg">
        </a> -->
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="slide slide4">
        <!--  <a routerLink="/home">
         <img src="assets\carousal\quick-loan-approval-and-disbursement.jpg">
        </a> -->
      </div>
    </ng-template>

  </ngb-carousel>

</div>







<!-- <div class="row justify-content-center">

  <div class="row">
    <div class="col-md-12">
      <h1 class="align-center">Elenmark Products Pvt. Ltd.</h1>
    </div>
  </div>

  
  <div class="row">
    <div class="col-md-1"></div>

    <div class="col-md-10">

      <p class="align-center">
        We Provide a wide range of Play Ground Equipments, School Furniture & Rubber-Flooring that delight and
        encourage every one
      </p>
    </div>
    <div class="col-md-1"></div>
  </div>


  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <a href="#aboutus">
          <i class="mbri-down mbr-iconfont"></i>
      </a>
    </div>
    <div class="col-md-1"></div>
  </div>


</div> -->