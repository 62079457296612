import { Component } from '@angular/core';

@Component({
  selector: 'app-jyothi-monthly-income-plan-widget',
  templateUrl: './jyothi-monthly-income-plan-widget.component.html',
  styleUrls: ['./jyothi-monthly-income-plan-widget.component.scss']
})
export class JyothiMonthlyIncomePlanWidgetComponent {

}
