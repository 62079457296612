<div class="container-fluid header">

  <div class="row">

    <div class="col-md-12">

      <div class="logo">
        <a routerLink="/">
          <img src="assets\logo\jyothi-co-op-credit-society-ltd-logo.png" height="100" width="120">
        </a>
      </div>

      <div class="jyothi">
        <span>
          <img src="assets\logo\company-image-1.png" height="100" >
          <!-- Jyothi Co-Op. Credit Society Ltd. -->
        </span>
      </div>

      <!-- <div class="regno">
        <span>
          <img src="assets\logo\goldloan.png" height="100" >
        </span>
      </div> -->

      <div class="regno">
        <span>
          REG. NO.BOM/RSR/1088/A OF 1981<br>
        </span>
      </div>

    </div>

  </div>
  <div class="col-md-1"></div>

</div>