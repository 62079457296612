<section class="container-fluid page bod-page">


  <div class="row header">
    <div class="col-md-12">
      <h1>Managing Committee</h1>
    </div>
  </div>

  <div class="row Chairman">
    <div class="col-md-1"></div>
    <div class="col-md-4"></div>
    <div class="col-md-2 director">
      <p class="designation">Chairman</p>
      <img src="assets/directors/salian-girish.jpg">
      <p class="name">Shri. Girish B. Salian</p>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-1"></div>
  </div>


  <div class="row">
    <div class="col-md-2"></div>


    <div class="col-md-2 director">
      <p class="designation">Vice chairman</p>
      <img src="assets/directors/kulal-devdas-p.jpg">
      <p class="name">Shri P. Devadas L. Kulal</p>
    </div>

    <div class="col-md-1"></div>

    <div class="col-md-2 director">
      <p class="designation">Secretory</p>
      <img src="assets/directors/moolya-shekhar-p.jpg">
      <p class="name">Shri. P. Shekhar Moolya </p>

    </div>

    <div class="col-md-1"></div>

    <div class="col-md-2 director">
      <p class="designation">Treasurer</p>
      <img src="assets/directors/bharati-arkyan.jpg">
      <p class="name">Smt. Bharati P. Arkyan</p>
    </div>

    <div class="col-md-1"></div>

  </div>



  <div class="row">
    <div class="col-md-12 align-center">
      <p class="members">Members</p>
    </div>
  </div>



  <div class="row">
    <div class="col-md-2"></div>


    <div class="col-md-2 director">
      <img src="assets/directors/moolya-d-i.jpg">
      <p class="name">Shri. Dombaya I. Moolya</p>
    </div>
    <div class="col-md-1"></div>

    <div class="col-md-2 director">
      <img src="assets/directors/moolya-candu.jpg">
      <p class="name">Shri. Chandu K. Moolya</p>
    </div>
    <div class="col-md-1"></div>

    <div class="col-md-2 director">
      <img src="assets/directors/kulal-surekha.jpg">
      <p class="name">Smt. Surekha R. Kulal</p>
    </div>
    <div class="col-md-1"></div>

  </div>


  <div class="row">
    <div class="col-md-2"></div>


    <div class="col-md-2 director">
      <img src="assets/directors/karkera-girish.jpg">
      <p class="name">Shri. Girish V. Karkera</p>
    </div>
    <div class="col-md-1"></div>

    <div class="col-md-2 director">
      <img src="assets/directors/salian-karunakar-b.jpg">
      <p class="name">Shri. Karunakar B. Salian</p>
    </div>
    <div class="col-md-1"></div>

    <div class="col-md-2 director">
      <img src="assets/directors/umanath-moolya.jpg">
      <p class="name">Shri. Umanath Moolya</p>
    </div>
    <div class="col-md-1"></div>

  </div>



  <div class="row">
    <div class="col-md-2"></div>


    <div class="col-md-2 director">
      <img src="assets/directors/banjan-devdas-m.jpg">
      <p class="name">Shri. Devdas M. Banjan</p>
    </div>
    <div class="col-md-1"></div>

    <div class="col-md-2 director">
      <img src="assets/directors/sopan-s-wankhede.jpg">
      <p class="name">Shri. Sopan S. Wankhede</p>
    </div>
    <div class="col-md-1"></div>

    <div class="col-md-2 director">
      <img src="assets/directors/karkera-d-venugopal.jpg">
      <p class="name">Shri. Venugopal D. Karkera</p>
      </div>
    <div class="col-md-1"></div>

  </div>


<div class="row">
  <div class="col-md-2"></div>

  <div class="col-md-1"></div>
  <div class="col-md-2 director">
  </div>
  
  <div class="col-md-2 director">
    <img src="assets/directors/manesh-pavitra.jpg">
    <p class="name">Smt. Pavitra Manesh</p>
  </div>
  
  <div class="col-md-2 director">
  </div>

  <div class="col-md-1"></div>
  <div class="col-md-1"></div>

</div>



</section>