<div class="inner-page">
  <div class="row">
    <div class="col-md-10">
      <h2>Jyothi Monthly Income Plan</h2>

      <table class="table table-bordered table-hover table-sm align-center">
        <thead class="thead-light">
          <tr>
            <th rowspan="2">Amount Invested</th>
            <th class="align-center">Interest @ 7% p.a</th>
            <th class="align-center">Interest @ 7.50% p.a</th>
            <th colspan="2" class="align-center">Interest @ 7% p.a</th>
          </tr>
          <tr>
            <th>12 Months Rs. P.</th>
            <th>14 Months Rs. P.</th>
            <th>24 Months Rs. P.</th>
            <th>36 Months Rs. P.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>10,000</td>
            <td>58.00</td>
            <td>62.00</td>
            <td>58.00</td>
            <td>58.00</td>
          </tr>

          <tr>
            <td>25,000</td>
            <td>145.00</td>
            <td>155.00</td>
            <td>145.00</td>
            <td>145.00</td>
          </tr>

          <tr>
            <td>50,000</td>
            <td>290.00</td>
            <td>311.00</td>
            <td>290.00</td>
            <td>290.00</td>
          </tr>

          <tr>
            <td>1,00,000</td>
            <td>580.00</td>
            <td>621.00</td>
            <td>580.00</td>
            <td>580.00</td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
</div>
