<div class="container-fluid page gallery-page">
  <div class="row header">
    <div class="col-md-12 ">
      <h1>Inauguration of “Jyothi” Collection Centre in Navi Mumbai </h1>
    </div>
  </div>




  <div class="content">

    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-2">
        <a [routerLink]="['/photo-gallery/']">
          Back
        </a>
      </div>
    </div>

    <div class="row">
        <div class="col-md-1"></div>

        <div class="col-md-10">
            
          <p>
              The 3rd branch–cum-collection Centre of Jyothi Co-operative Credit Society Ltd in Navi Mumbai 
              area was inaugurated by Chief Guest, Mr. K. D. Shetty, Chairman, Matrubhoomi Co-op. Credit Society Ltd 
              on 08-07-2012 at 11.00 am at Haware Centurion, Shop No.S-07-14/15, Plot no.88/91, 
              Sector No.19A, Nerul, Near Seawood Railway Station, Navi Mumbai – 400 706. The guest of honour 
              Mr. Santhosh D. Shetty, Corporator, Navi Mumbai Mahanagarpalika performed the initial operation 
              of office-computer system and another guest of honour, Mr. Ramesh M. Poojary, 
              Trustee of Nerul Shaneeshwar Temple performed the opening of office ‘Safe Locker”. 
              These proceedings were preceded by Gana Homa and Lakshmi Pooja performed by 
              Mr. Balakrishna Bhat of Nerul which was participated by Dr. Harish B. Salian 
              and Dr. Suchitra H. Salian.
          </p>

        </div>
    </div>




    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">

        <div *ngFor="let image of album; let i=index">
          <div class="image-thumb">
            <img [src]="image.thumb" (click)="open(i)" height="200" width="200" />
          </div>
        </div>
      </div>

    </div>
  </div>

</div>