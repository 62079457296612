<div class="inner-page">

  <div class="row">
    <div class="col-md-12">
      <h2>Loan Rules</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-md-11">
      <ol>
        <li>The borrower as well as sureties should be members of the Society before applying for loan</li>
        <li>Every member should make an application for loan on the prescribed loan form of the society</li>
        <li>The Managing Committee has a right to reject any loan application without assigning any reason.</li>
        <li>The Managing committee has the right to recall the loan at any time if it is not utilized for which purpose
          it has been granted.</li>
        <li>The rate of interest on loans shall be subject to change from time to time as per discretion of the managing
          committee. The penal interest in case of default shall be @2% of the defaulted amount.</li>
        <li> Loans are repayable in 10/36/60/84/120/180 months.</li>
        <li> The valuation & legal opinion of movable/immovable property will be done by the Society’s approved
          valuers/advocates. The charges on this behalf to be borne by the borrower.</li>
        <li> The borrower has to invest in shares as a linkage of loan as follows:
          <ul>
            <li>a) In case of Unsecured Loan – 5%</li>
            <li>b) Secured Loan - 2.5%</li>
          </ul>
        </li>
        <li> One Member can stand as surety for 2 borrowers only and family members surety is not acceptable.</li>
        <li> If the applicant has not availed the loan within 3 months from the date of sanction of loan, the said
          sanction has to be revalidated with borrower’s request for further disbursement.</li>
        <li> Applicant member or guarantor should not be a defaulter as a borrower guarantor.</li>
      </ol>






      
    </div>
    <div class="row">
        <div class="col-md-11">
            <p> The above rules can be modified with prior permission of the Registrar of Co-operative Societies.
                Applicants have to abide by all the terms, conditions, rules and regulations made by the Society from time to
                time.</p>
      
        </div>
      </div>
  </div>


</div>