<div class="inner-page">
  <div class="row">
    <div class="col-md-11">
      <h2>Recurring Deposit</h2>

      <table class="table table-bordered table-hover table-sm">
        <thead class="thead-light">
          <tr>
            <th rowspan="3">Amount Invested</th>
            <th colspan="7" class="align-center">
              Total Amount Repayable on Maturity
            </th>
          </tr>
          <tr>
            <th class="align-center">Interest @ 7% p.a</th>
            <th class="align-center">Interest @ 7.50% p.a</th>
            <th colspan="5" class="align-center">Interest @ 7% p.a.</th>
          </tr>
          <tr>
            <th>12 Months</th>
            <th>15 Months</th>
            <th>24 Months</th>
            <th>36 Months</th>
            <th>48 Months</th>
            <th>60 Months</th>
            <th>84 Months</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>500</td>
            <td>6,232</td>
            <td>7,886</td>
            <td>12,915</td>
            <td>20,082</td>
            <td>27,766</td>
            <td>36,005</td>
            <td>44,841</td>
          </tr>
          <tr>
            <td>1,000</td>
            <td>12,465</td>
            <td>15,772</td>
            <td>25,831</td>
            <td>40,163</td>
            <td>55,531</td>
            <td>72,011</td>
            <td>89,681</td>
          </tr>

          <tr>
            <td>1,500</td>
            <td>18,697</td>
            <td>23,658</td>
            <td>38,746</td>
            <td>30,245</td>
            <td>83,297</td>
            <td>1,08,016</td>
            <td>1,34,522</td>
          </tr>

          <tr>
            <td>2,000</td>
            <td>24,930</td>
            <td>31 545</td>
            <td>51,662</td>
            <td>80,326</td>
            <td>1,11,063</td>
            <td>1,44,021</td>
            <td>1,79,362</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
