<section class="container-fluid branch-widget">
  <div class="container">
    <header class="branch-header align-center">
      <h3>Branches</h3>
      <div class="divider">
        <hr class="line1">
        <hr class="line2">
        <hr class="line1">
      </div>
    </header>
    <div class="row">
      <div class="col-md-6 col-lg-5 offset-lg-1">
        <div class="box">
          <h4 class="title"><a routerLink="/branches">Fort Branch</a></h4>
          <p class="description">
            102, Malhotra Chembers,
            31/33, Police Court Lane,
            Behind Handloom House, Fort,
            Mumbai-400 001.
          </p>
        </div>
      </div>
      <div class="col-md-6 col-lg-5" data-wow-duration="1.4s">
        <div class="box">
          <h4 class="title"><a routerLink="/branches">Kalyan Branch</a></h4>
          <p class="description">
            302, Krishna Complex,
            Valipeer Road,
            Nr. Bhanu Sagar Cinema,
            Kalyan(w)-421 301.
          </p>
        </div>
      </div>
      <div class="col-md-6 col-lg-5 offset-lg-1">
        <div class="box">
          <h4 class="title"><a routerLink="/branches">Borivali Branch</a></h4>
          <p class="description">
            Hari Om Plaza, Office No.111,
            1st Floor, M. G. Road,
            Near S.T. Stand, Borivali(E),
            Mumbai-400 066.
          </p>
        </div>
      </div>
      <div class="col-md-6 col-lg-5">
        <div class="box">
          <h4 class="title"><a routerLink="/branches">Nerul Branch</a></h4>
          <p class="description">
            Haware's Certurion, Shop No.S-07-14/15,
            Plot No. 88/91, Sector No.19A,
            Nerul, Near Seawood Rly. Station,
            Navi Mumbai-400 706.
          </p>
        </div>
      </div>
      <div class="col-md-6 col-lg-5 offset-lg-1">
        <div class="box">
          <h4 class="title"><a routerLink="/branches">Nallasopara Branch</a></h4>
          <p class="description">
            Shop No. 9, New yesh CHS Ltd,
            Yesh Apartment, Chakradhar Nagar,
            Near Hanuma Nagar,
            Nallasopara(W), 401 203.
          </p>
        </div>
      </div>
      <div class="col-md-6 col-lg-5">
        <div class="box">
          <h4 class="title"><a routerLink="/branches">Pune (katraj) Branch</a></h4>
          <p class="description">
            Shop No. 8/9, Thate Complex,
            1st Floor, Survey No. 42,
            Dattanagar, Ambegaon,
            Budruk, Taluk Haveli,
            Katraj, Pune-411 046.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>