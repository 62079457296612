<div class="container-fluid page">

  <div class="row header">
    <div class="col-md-12 align-center">
      <h1>Branches</h1>
    </div>
  </div>

  <div class="content">

    <div class="row">
      <div class="col-md-1"></div>

      <div class="col-md-10 align-center">


        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-light">
            <tr>
              <th>Branch Name</th>
              <th>Address</th>
              <th>Phone Number</th>
              <th>Map</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p class="branchName">Head Office</p>
              </td>
              <td>
                <p>
                  102, Malhotra Chembers, <br>
                  31/33, Police Court Lane,<br>
                  Behind Handloom House, Fort, <br>
                  Mumbai-400 001.<br>
                </p>
              </td>
              <td>
                <p>
                  4961 1062,<br>
                  9137680554
                </p>
              </td>
              <td>
                <p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3773.9002458809077!2d72.83231031437475!3d18.935809061286005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d1db8d43b511%3A0xc6cf8e0c419e9011!2sKulala%20Sangha%20Mumbai%20-%20Jyothi%20Co-Operative%20Credit%20Society%20Limited!5e0!3m2!1sen!2sin!4v1576905170042!5m2!1sen!2sin"
                    width="350" height="200" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p class="branchName">Kalyan Branch</p>
              </td>
              <td>
                <p class="branchAddress">
                  302, Krishna Complex,<br>
                  Valipeer Road,<br>
                  Nr. Bhanu Sagar Cinema,<br>
                  Kalyan(w)-421 301.<br>
                </p>
              </td>
              <td>
                <p class="branchTelNo">
                  0251-231 3811
                </p>
              </td>
              <td>
                <p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241093.008426057!2d72.98694496860594!3d19.23542091448015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be795ce270a7a81%3A0xe2f9e336640135f!2sRoyal%20Paper%20Traders!5e0!3m2!1sen!2sin!4v1576905564904!5m2!1sen!2sin"
                    width="350" height="200" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p class="branchName">Borivali Branch</p>
              </td>
              <td>
                <p class="branchAddress">
                  Hari Om Plaza, Office No.111,<br>
                  1st Floor, M. G. Road,<br>
                  Near S.T. Stand, Borivali(E),<br>
                  Mumbai-400 066.<br>
                </p>
              </td>
              <td>
                <p class="branchTelNo">
                  2891 3965
                </p>
              </td>
              <td>
                <p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1583.8976280388542!2d72.86087805588845!3d19.231654415550132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b0d1bc8cd01f%3A0x5fde5207e9aa0924!2sVaisansar%20Technologies%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1576905979716!5m2!1sen!2sin"
                    width="350" height="200" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p class="branchName">Nerul Branch</p>
              </td>
              <td>
                <p class="branchAddress">
                  Haware's Certurion, Shop No.S-07-14/15,<br>
                  Plot No. 88/91, Sector No.19A,<br> Nerul,
                  Near Seawood Rly. Station,<br>
                  Navi Mumbai-400 706.<br>
                </p>
              </td>
              <td>
                <p class="branchTelNo">
                  2771 1217
                </p>
              </td>
              <td>
                <p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.886305953362!2d73.020061214376!3d19.024730958527083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c35c6b93378b%3A0x3df64e4c5e4b7f1e!2sHaware%20Centurion%20Mall%2C%20Sector%2019A%2C%20Nerul%2C%20Navi%20Mumbai!5e0!3m2!1sen!2sin!4v1576906164599!5m2!1sen!2sin"
                    width="350" height="200" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p class="branchName">Nallasopara Branch</p>
              </td>
              <td>
                <p class="branchAddress">
                  Shop No. 9, New yesh CHS Ltd,<br>
                  Yesh Apartment, Chakradhar Nagar,<br>
                  Near Hanuma Nagar,<br>
                  Nallasopara(W), 401 203.<br>
                </p>
              </td>
              <td>
                <p class="branchTelNo">
                  91379 46272
                </p>
              </td>
              <td>
                <p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15051.364366602433!2d72.80901480327074!3d19.41927075567373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a96a0d15f971%3A0x874017ca77261660!2sNew%20Yash%20Apartment!5e0!3m2!1sen!2sin!4v1576906903875!5m2!1sen!2sin"
                    width="350" height="200" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p class="branchName">Pune (katraj) Branch</p>
              </td>
              <td>
                <p class="branchAddress">
                  Shop No. 8/9, Thate Complex,<br>
                  1st Floor, Survey No. 42,
                  Dattanagar, Ambegaon,<br>
                  Budruk, Taluk Haveli,<br>
                  Katraj, Pune-411 046.<br>
                </p>
              </td>
              <td>
                <p class="branchTelNo">
                  020-2431 8171
                </p>
              </td>
              <td>
                <p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.743577291539!2d73.84828651436773!3d18.449948176146872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eace35c88ac3%3A0xaf365a7d4b3d8db8!2sThate%20Building%2C%20Katraj%20-%20Ambegaon%20BK%20Rd%2C%20Dattanagar%2C%20Ambegaon%20BK%2C%20Pune%2C%20Maharashtra%20411046!5e0!3m2!1sen!2sin!4v1576906982100!5m2!1sen!2sin"
                    width="350" height="200" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-md-1"></div>
    </div>
  </div>

</div>