<div class="container-fluid menu scrollto">
  <nav class="navbar navbar-expand-md">
    <button
      class="navbar-toggler ml-auto custom-toggler"
      type="button"
      data-toggle="collapse"
      (click)="isCollapsed = !isCollapsed">
      <span class="navbar-toggler-icon">
        <img src="assets/logo/menu.png" width="25px" height="25px">
      </span>
    </button>

    <div
      class="collapse navbar-collapse"
      [ngbCollapse]="isCollapsed"
      id="navbarSupportedContent">
      <ul class="nav navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link text-center" routerLink="/">Home</a>
        </li>
        <li ngbDropdown class="dropdown nav-item">
          <a
            ngbDropdownToggle
            class="nav-link text-center dropdown-toggle"
            id="dropdown-products"
            data-toggle="dropdown"
            aria-expanded="false"
            >About Us
          </a>
          <div ngbDropdownMenu aria-labelledby="dropdown-products">
            <a class="dropdown-item" routerLink="/about-us"> About Us </a>
            <a class="dropdown-item" routerLink="/board-of-directors">
              Board of Directors
            </a>
          </div>
        </li>
        <li ngbDropdown class="dropdown nav-item">
          <a
            ngbDropdownToggle
            class="nav-link text-center dropdown-toggle"
            id="dropdown-products"
            data-toggle="dropdown"
            aria-expanded="false"
            >Services
          </a>
          <div ngbDropdownMenu aria-labelledby="dropdown-products">
            <a class="dropdown-item" routerLink="/deposits"> Deposits </a>
            <a class="dropdown-item" routerLink="/loans"> Loans </a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" routerLink="/branches">Branches</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" routerLink="/photo-gallery"
            >Photo Gallery</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" routerLink="/download">Download</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" routerLink="/contact-us"
            >Contact Us</a
          >
        </li>
      </ul>
    </div>
  </nav>
</div>
