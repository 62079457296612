<div class="inner-page">

  <div class="row">
    <div class="col-md-12">
      <h2>Loan schemes</h2>
      <p>Loans available at moderate interest rates</p>
    </div>
  </div>


  <div class="row">
    <div class="col-md-10">
      <img src="assets\gallery\gold-loan-scheme.png">
    </div>  
    <div class="col-md-1"></div>
  </div>  
  <div class="row">
    <div class="col-md-10">
      <table class="table table-bordered table-hover table-sm">
        <thead class="thead-light">
          <tr>
            <th class="col1">Loan types</th>
            <th class="col2">Period</th>
            <th class="col3" colspan="2">Loan Amount</th>
            <th class="col5"> Interest Rate</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td class="col1" rowspan="2">Secured Loan</td>
            <td class="col2" rowspan="2">Upto 10 Years</td>
            <td class="col3" colspan="2">Upto Rs. 25,00,000/-
            <td class="col5">12.5%</td>
          </tr>
          <tr>
            <td class="col3"> Rs. 25,00,001/- <br>(Joint Borrowing)</td>
            <td class="col4">Rs. 35,00,000/- <br>(Joint Borrowing)</td>
            <td class="col5">13.00%</td>
          </tr>


          <tr>
            <td class="col1" rowspan="2">Housing Loan</td>
            <td class="col2" rowspan="2">Upto 15 Years</td>
            <td class="col3" colspan="2">Upto Rs. 25,00,000/-</td>
            <td class="col5">9.25%</td>
          </tr>
          <tr>
            <td class="col3"> Rs. 25,00,001/- <br>(Joint Borrowing)</td>
            <td class="col4">Rs. 35,00,000/- <br>(Joint Borrowing)</td>
            <td class="col5">9.75%</td>
          </tr>
          

          <tr>
            <td class="col1">Educational Loan</td>
            <td class="col2">Upto 10 Years</td>
            <td class="col3" colspan="2">Upto Rs. 10,00,000/-</td>
            <td class="col5">9.00%</td>
          </tr>


          <tr>
            <td class="col1"rowspan="2">Gold Loan</td>
            <td class="col2"rowspan="2">1 Years</td>
            <td class="col3"colspan="2">Upto Rs. 5,00,000/-</td>
            <td class="col5">9.75% </td>
          </tr>
          <tr>
            <td class="col3">Above Rs. 5,00,000/- </td>
            <td class="col4">Rs. 10,00,000/- </td>
            <td class="col5">10.00% <br>
                      (0 % Processing Fee)</td>
          </tr>



          <tr>
            <td class="col1" rowspan="2">Personal Loan</td>
            <td class="col2" rowspan="2">Upto 5 Years</td>
            <td class="col3" colspan="2">Rs. 50,000 to Rs. 2,00,000/- <br>
            <td class="col5">14%</td>
          </tr>
          <tr>           
            <td class="col3" colspan="2">
              Rs. 3,50,000/- 
             <br> (Company Salary Deduction)
            </td>
            <td class="col5">14%</td>
          </tr>



          <tr>
              <td class="col1" rowspan="1">Short Term Loan</td>
              <td class="col2" rowspan="1">Upto 10 Months</td>
              <td class="col3" colspan="2">Upto Rs. 20,000/-</td>
              <td class="col5">13.50%</td>
          </tr>
          <tr>
              <td class="col3"></td>
              <td class="col3">Upto 24 Months</td>
              <td class="col4"colspan="2">20,000/- To 50,000/-</td>
              <td class="col5">13.50%</td>
          </tr>

        </tbody>
      </table>
    </div>
    <div class="col-md-1"></div>
  </div>
  <div class="row">
    <div class="col-md-10">
      <table class="table table-bordered table-hover table-sm">
        <thead class="thead-light">
          <tr>
            <th class="col1">Loan Charges</th>
            <th class="">Purpose</th>
            <th class="col2">Rate</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td class="col1">Application Form Fee</td>
          <td></td>
          <td class="col2">Rs. 50</td>
        </tr>

        <tr>
          <td rowspan=3 class="col1">Incidental Charges</td>
          <td>Personal & Emergency Loan</td>
          <td class="col2">2% Loan</td>
        </tr>

        <tr>
          <td>Mortgage & Gold Loan</td>
          <td class="col2">1.5% Loan</td>
        </tr>

        <tr>
          <td>Housing Loan</td>
          <td class="col2">1% Loan</td>
        </tr>
        <tr>


          <tr>
            <td rowspan=3 class="col1">Share Capital</td>
            <td>Personal & Emergency Loan</td>
            <td class="col2">5% Loan</td>
          </tr>

          <tr>
            <td>Mortgage & Gold Loan</td>
            <td class="col2">2.5% Loan <br>
            (Max of Rs. 50,000/-)</td>
          </tr>

          <tr>
            <td>Housing Loan</td>
            <td class="col2">1% Loan</td>
          </tr>
          <tr>



            <tr>
              <td rowspan=3 class="col1">Stamp Duty Charges</td>
              <td>Personal Loan</td>
              <td class="col2">Rs. 100</td>
            </tr>
  
            <tr>
              <td rowspan="2">Housing & <br>
              House Property Mortgage Loan</td>
              <td class="col2">0.1% of Loan <br>
              (For Loan Upto 5,00,000)</td>
            </tr>
  

            <tr>
              <td class="col2">0.3% of Loan <br>
              (For Loan above 5,00,000)</td>
            </tr>


            <tr>
              <td class="col1">Franking & Handling Charges</td>
              <td></td>
              <td class="col2">Rs. 30</td>
            </tr>


        </tbody>
      </table>
    </div>
    <div class="col-md-1"></div>
  </div>
  

</div>