import { Component, OnInit } from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-carousel-widget',
  templateUrl: './carousel.widget.component.html',
  styleUrls: ['./carousel.widget.component.css']
})
export class CarouselWidgetComponent implements OnInit {


  constructor(config: NgbCarouselConfig) {
    // customize default values of carousels used by this component tree
    config.showNavigationArrows = false;
    config.showNavigationIndicators = true;
    config.pauseOnHover=false;
    config.wrap=true;
    config.interval=8000;
  }

  ngOnInit() {
  }

}
