<div class="inner-page">
  <div class="row">
    <div class="col-md-12">
      <h2>Jyothi Lakhpati Scheme (Recurring Investment Plan)</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <table class="table table-bordered table-hover table-sm">
        <thead class="thead-light">
          <tr>
            <th>Period months</th>
            <th>Amount Rs.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>12</td>
            <td>8,023</td>
          </tr>
          <tr>
            <td>15</td>
            <td>6,341</td>
          </tr>
          <tr>
            <td>24</td>
            <td>3,872</td>
          </tr>
          <tr>
            <td>36</td>
            <td>2,490</td>
          </tr>
          <tr>
            <td>48</td>
            <td>1,801</td>
          </tr>
          <tr>
            <td>60</td>
            <td>1,389</td>
          </tr>
          <tr>
            <td>72</td>
            <td>1,116</td>
          </tr>
          <tr>
            <td>84</td>
            <td>921</td>
          </tr>
          <tr>
            <td>96</td>
            <td>776</td>
          </tr>
          <tr>
            <td>108</td>
            <td>664</td>
          </tr>
          <tr>
            <td>120</td>
            <td>575</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
