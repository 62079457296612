<div class="container-fluid loans-page page">

  <div class="row header">
    <div class="col-md-12">
      <h1>Loans & Advances</h1>
    </div>
  </div>


  <div class="row">

    <nav class="col-md-2 bg-light">

      <ul class="nav navbar-nav">

        <li class="nav-item">
          <a class="nav-link active" [routerLink]="['/loans']">
            Loan Schemes
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link " [routerLink]="['rules']">
            Loan Rules
          </a>
        </li>

       
       

       
       
      </ul>

    </nav>

    <div class="col-md-10">
      <router-outlet></router-outlet>
    </div>

  </div>

</div>