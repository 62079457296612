<div class="container-fluid productsWidget">

  <div class="row header" id="header">
    <div class="col-md-12">
      <h1 class="align-center">Our Services</h1>
      <div class="divider">
        <hr class="line1">
        <hr class="line2">
        <hr class="line1">
      </div> 
    </div>
  </div>


  <div class="row">



    <div class="col-md-6">
      <div class=" productCard">
        <h3 class="productName">
          <a [routerLink]="['/deposits']" routerLinkActive="router-link-active">
            Deposists
          </a>
        </h3>
        <p class="productRemark">Invest in our attractive Deposit Schemes and earn. Our schemes include long term
          deposit,
          short term deposits, Monthly interest payment Schemes, Recuring Deposits etc</p>
        <!-- <div class="productImage">
          <a [routerLink]="['/deposits']" routerLinkActive="router-link-active">
            <img height="150" width="100%" src="assets/services/deposits-img.jpg">
          </a>
        </div> -->
        <div class="productreadMore" style="align-content:flex-end">
          <button>
            <a [routerLink]="['/deposits']" routerLinkActive="router-link-active">
              &nbsp;<fa-icon [icon]="['fas','arrow-right']"></fa-icon>&nbsp;&nbsp;Read More
            </a>
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class=" productCard">
        <h3 class="productName">
          <a [routerLink]="['/loans']" routerLinkActive="router-link-active">
            Loans & Advances
          </a>
        </h3>
        <p class="productRemark">We offer wide range of loan products like Personal, Business, Vehicle & Gold loans. Our
          loans tailored to suit every individual’s requirement based on their earnings, securities and repayment
          capacity</p>
        <!-- <div class="productImage">
          <a [routerLink]="['/loans']" routerLinkActive="router-link-active">
            <img height="150" width="100%" src="assets/services/deposits-img.jpg">
          </a>
        </div> -->
        <div class="productreadMore" style="align-content:flex-end">
          <button>
            <a [routerLink]="['/loans']" routerLinkActive="router-link-active">
              &nbsp;<fa-icon [icon]="['fas','arrow-right']"></fa-icon>&nbsp;&nbsp;Read More
            </a>
          </button>
        </div>
      </div>
    </div>

  </div>

  <!-- <div class="row">

    <div class="col-md-2"></div>
    <div class="col-md-4">
      <div class="card">
        <img class="card-img-top" src="assets/services/deposits-img.jpg" alt="Card image cap">
        <i class="fa fa-money"></i>
        <div class="card-body">
          <h5 class="card-title">Deposits</h5>
          <p class="card-text">Invest in our attractive Deposit Schemes and earn. Our schemes include long term deposit,
            short term deposits, Monthly interest payment Schemes, Recuring Deposits etc</p>
          <a routerLink="/deposits" class="readmore">Read more </a>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card">
        <i class="fa fa-hand-lizard-o"></i>
        <div class="card-body">
          <h5 class="card-title">Loans</h5>
          <p class="card-text">We offer wide range of loan products like Personal, Business, Vehicle & Gold loans</p>
          <a routerLink="/loans" class="readmore">Read more </a>
        </div>
      </div>
    </div>
    <div class="col-md-2"></div>
  </div> -->


</div>