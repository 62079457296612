<div class="container-fluid page gallery-page">
  <div class="row header">
    <div class="col-md-12 ">
      <h1>Events & Photo Gallery</h1>
    </div>
  </div>




  <div class="content">
    <div class="row">
      <div class="col-md-1"></div>



      <div class="col-md-3 card" style="width: 18rem;">
          <img class="card-img-top" src="../../assets/gallery/navi-mumbai-branch-opening/thumb.jpg" alt="Card image cap">
          <div class="card-body">
            <h5 class="card-title">Navi Mumbai Collection Center</h5>
            <p class="card-text">The 3rd branch–cum-collection Centre in Navi Mumbai 
              area was inaugurated by Chief Guest, Mr. K. D. Shetty, Chairman, Matrubhoomi Co-op. Credit Society Ltd 
              on 08-07-2012</p>
            <a [routerLink]="['/photo-gallery/navi-mumbai-branch-opening']" class="btn btn-primary">Go</a>
          </div>
        </div>







    </div>
  </div>

</div>