import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { BoardOfDirectorsComponent } from './aboutus/board-of-directors.component';
import { InterestRateComponent } from './services/deposits/interest-rate/interest-rate.component';
import { LoansHomeComponent } from './services/loans/loans-home.component';
import { BranchesComponent } from './branches/branches.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './aboutus/about-us.component';
import { DoubleBenefitPlanWidgetComponent } from './services/deposits/double-benefit/double-benefit-plan-widget.component';
import { JyothiMonthlyIncomePlanWidgetComponent } from './services/deposits/monthly-income/jyothi-monthly-income-plan-widget.component';
import { JyothiLakhpatiComponent } from './services/deposits/lakhpati-scheme/jyothi-lakhpati.component';
import { RecurringDepositWidgetComponent } from './services/deposits/recurring-deposit/recurring-deposit-widget.component';
import { PhotoGalleryComponent } from './gallery/photo-gallery.component';
import { DownloadComponent } from './download/download.component';
import { DepositsHomeComponent } from './services/deposits/deposits-home.component';
import { LoanSchemesComponent as LoanSchemesComponent } from './services/loans/loan-schemes/loan-schemes.component';
import { LoanRulesComponent } from './services/loans/loan-rules/loan-rules.component';
import { NavimumbaiBranchOpeningComponent } from './gallery/navimumbai-branch-opening/navimumbai-branch-opening.component';

const routes: Routes = [ 
  {path:'',component: HomeComponent,pathMatch:'full'},
  
  {path:'branches',component: BranchesComponent},
  {path:'contact-us',component: ContactUsComponent},
  {path:'about-us',component: AboutUsComponent},
  {path:'board-of-directors',component: BoardOfDirectorsComponent},

  {path:'deposits',component:DepositsHomeComponent,
      children: [
        {path:'',component: InterestRateComponent},
        {path:'double-benefit-plan',component: DoubleBenefitPlanWidgetComponent},
        {path:'jyothi-monthly-income-plan',component: JyothiMonthlyIncomePlanWidgetComponent},
        {path:'jyothi-lakhpati-scheme',component: JyothiLakhpatiComponent},
        {path:'recurring-deposit',component: RecurringDepositWidgetComponent},
      ]},

  {path:'loans',component: LoansHomeComponent,
    children: [
      {path:'',component: LoanSchemesComponent},
      {path:'rules',component: LoanRulesComponent},
    ]},

  {path:'photo-gallery',
    children: [
      {path:'',component: PhotoGalleryComponent},
      {path:'navi-mumbai-branch-opening',component: NavimumbaiBranchOpeningComponent},
    ]},

  {path:'download',component: DownloadComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled', // Add options right here
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
