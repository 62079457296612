<div class="container-fluid page">
  <div class="row header">
    <div class="col-md-12">
      <h1>Downloads</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-md-1"></div>

    <div class="col-md-10">
      <h2>A/c Openinig Forms</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-md-1"></div>

    <div class="col-md-2">
      <a href="assets/downloads/account-opening/membership-form.pdf">
        Membership form
      </a>
    </div>

    <div class="col-md-2">
      <a
        href="assets/downloads/account-opening/sb-account-application-form.pdf"
      >
        SB Account Opening Form
      </a>
    </div>

    <div class="col-md-2">
      <a href="assets/downloads/account-opening/kyc-form.pdf"> KYC Form </a>
    </div>

    <div class="col-md-2">
      <a href="assets/downloads/account-opening/share-refund-form.pdf">
        Share Refund Form
      </a>
    </div>

    <div class="col-md-2"></div>
  </div>

  <div class="row">
    <div class="col-md-1"></div>

    <div class="col-md-10">
      <h2>Deposits</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-md-1"></div>

    <div class="col-md-2">
      <a href="assets/downloads/deposits/fixed-deposit-form.pdf">
        Fixed Deposit Form
      </a>
    </div>

    <div class="col-md-2">
      <a href="assets/downloads/deposits/rd-and-lakhpati-form.pdf">
        Recurring Deposit & Lakhpati Scheme Form
      </a>
    </div>

    <div class="col-md-2"></div>

    <div class="col-md-2"></div>
  </div>

  <div class="row">
    <div class="col-md-1"></div>

    <div class="col-md-10">
      <h2>Others</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-md-1"></div>

    <div class="col-md-2">
      <a href="assets/downloads/loans/loan-against-deposit-form.pdf">
        Loan Against Deposits Forms
      </a>
    </div>

    <div class="col-md-2">
      <a href="assets/downloads/vouchers/cash-voucher.pdf"> Cash Voucher </a>
    </div>

    <div class="col-md-2"></div>

    <div class="col-md-2"></div>
  </div>

  <div class="row">
    <div class="col-md-1"></div>

    <div class="col-md-10">
      <h2>Annual Reports</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-md-1"></div>

    <div class="col-md-2">
      <a href="assets/downloads/annual-reports/annual-report-2023-24.pdf">
        2023-24
      </a>
    </div>

    <div class="col-md-2">
      <a href="assets/downloads/annual-reports/annual-report-2022-23.pdf">
        2022-23
      </a>
    </div>

    <div class="col-md-2">
      <a href="assets/downloads/annual-reports/annual-report-2021-22.pdf">
        2021-22
      </a>
    </div>

    <div class="col-md-2">
      <a href="assets/downloads/annual-reports/annual-report-2020-21.pdf">
        2020-21
      </a>
    </div>

    <div class="col-md-2">
      <a href="assets/downloads/annual-reports/annual-report-2019-20.pdf">
        2019-20
      </a>
    </div>
  </div>

  <div class="row">

    <div class="col-md-1"></div>
    
    <div class="col-md-2">
      <a href="assets/downloads/annual-reports/annual-report-2018-19.pdf">
        2018-19
      </a>
    </div>

    <div class="col-md-2"></div>

    <div class="col-md-2"></div>
  </div>
</div>
