import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { LightboxModule } from 'ngx-lightbox';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { faArrowCircleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './menu/header/header.component';
import { MenuComponent } from './menu/menu/menu.component';
import { FooterComponent } from './menu/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AboutUsWidgetComponent } from './home/widgets/aboutus/about-us-widget.component';
import { BoardOfDirectorsComponent } from './aboutus/board-of-directors.component';
import { InterestRateComponent } from './services/deposits/interest-rate/interest-rate.component';
import { LoansHomeComponent } from './services/loans/loans-home.component';
import { BranchesComponent } from './branches/branches.component';
import { ContactUsWidgetComponent } from './home/widgets/contactus/contact-us-widget.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ServiceWidgetComponent } from './home/widgets/services/service-widget.component';
import { BranchWidgetComponent } from './home/widgets/branch/branch-widget.component';
import { AboutUsComponent } from './aboutus/about-us.component';
import { DoubleBenefitPlanWidgetComponent } from './services/deposits/double-benefit/double-benefit-plan-widget.component';
import { JyothiMonthlyIncomePlanWidgetComponent } from './services/deposits/monthly-income/jyothi-monthly-income-plan-widget.component';
import { JyothiLakhpatiComponent } from './services/deposits/lakhpati-scheme/jyothi-lakhpati.component';
import { RecurringDepositWidgetComponent } from './services/deposits/recurring-deposit/recurring-deposit-widget.component';
import { PhotoGalleryComponent } from './gallery/photo-gallery.component';
import { DownloadComponent } from './download/download.component';
import { CarouselWidgetComponent } from './home/widgets/carousel/carousel.widget.component';
import { DepositsHomeComponent } from './services/deposits/deposits-home.component';
import { LoanSchemesComponent } from './services/loans/loan-schemes/loan-schemes.component';
import { LoanRulesComponent } from './services/loans/loan-rules/loan-rules.component';
import { NavimumbaiBranchOpeningComponent } from './gallery/navimumbai-branch-opening/navimumbai-branch-opening.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
//import { ScullyLibModule } from '@scullyio/ng-lib';





@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    CarouselWidgetComponent,
    FooterComponent,
    HomeComponent,
    AboutUsWidgetComponent,
    BoardOfDirectorsComponent,
    InterestRateComponent,

    BranchesComponent,
    ContactUsWidgetComponent,
    ContactUsComponent,

    ServiceWidgetComponent,
    BranchWidgetComponent,
    AboutUsComponent,

    DepositsHomeComponent, DoubleBenefitPlanWidgetComponent, JyothiMonthlyIncomePlanWidgetComponent,
    JyothiLakhpatiComponent, RecurringDepositWidgetComponent,

    LoansHomeComponent, LoanSchemesComponent, LoanRulesComponent,

    PhotoGalleryComponent, NavimumbaiBranchOpeningComponent,

    DownloadComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule,
    FormsModule,
    LightboxModule,
    FontAwesomeModule,
    ScullyLibModule,
    //ScullyLibModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {


  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faArrowCircleRight,faArrowRight);
  }
}
