<div class="container-fluid footer">


  <div class="row">
    <div class="col-md-1"></div>

    <div class="col-md-3 contact">
      <div class="heading text-uppercase">Contact us</div>
      <ul class="list-styles">
        <li>
          <i class="fa fa-envelope"></i> <a class="jyothi-mail" href="mailto:jyothisoc@gmail.com">
            jyothisoc@gmail.com</a>
        </li>
        <li>
          <i class="fa fa-phone"></i> 4961 1062<br>
          <i class="fa fa-phone"></i> 9137680554<br>
        </li>
        <li class="address">
          <p><i class="fa fa-building"></i> 102, Malhotra Chambers,<br>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 31/33, Police Court Lane,<br>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Behind Handloom House,<br>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Fort, Mumbai-400 001.</p>
        </li>
      </ul>
    </div>



    <div class="col-md-3 footer-branch">
      <div class="heading text-uppercase">Branch</div>
      <ul class="list-styles">
        <li><i class="fa fa-check"></i> <a class="branch" routerLink="/branches">Fort Branch</a></li>
        <li><i class="fa fa-check"></i> <a class="branch" routerLink="/branches">Borivali Branch</a></li>
        <li><i class="fa fa-check"></i> <a class="branch" routerLink="/branches">Kalyan Branch</a></li>
        <li><i class="fa fa-check"></i> <a class="branch" routerLink="/branches">Nerul Branch</a></li>
        <li><i class="fa fa-check"></i> <a class="branch" routerLink="/branches">Nallasopara Branch</a></li>
        <li><i class="fa fa-check"></i> <a class="branch" routerLink="/branches">Pune Branch</a></li>
      </ul>
    </div>

    <div class="col-md-4 footer-followers">
      <div class="heading text-uppercase">Follow Us</div>

      <ul class="social list-styles">
        <li class="facebook"><span><i class="fa fa-facebook"></i></span><a routerLink="/">Facebook</a></li>
        <li class="youtube"><span><i class="fa fa-youtube"></i></span><a routerLink="/">Youtube</a>
        </li>
      </ul>
      <br>
      <p>&copy; Jyothi Credit Society Ltd. All Rights Reserved.</p>
    </div>

    <div class="col-md-1"></div>
    
  </div>
  
  <div class="footer-down">
    <div class="row">
      <div class="col-md-12 align-center">
        <a class="bhoomi" href="http://bhoomiinfosolutions.com/" target="_blank">
          Designed & Hosted by : Bhoomi Infosolutions (I) Pvt. Ltd.
        </a>
      </div>
    </div>
  </div>

</div>