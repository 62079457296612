<section class="container-fluid about-us-widget">

  <div class="row about-us-widget-header">
    <div class="col-md-1"></div>

    <div class="col-md-10 align-center">
      <h1>Welcome To Jyothi Co-Op Credit Society Ltd.</h1>
      <div class="divider">
        <hr class="line1">
        <hr class="line2">
        <hr class="line1">
      </div> 
    </div>

    <div class="col-md-1"></div>

  </div>


  <div class="row about-us-widget-content">

    <div class="col-md-1"></div>

    <div class="col-md-10">
      <p>
        
        Dispelling darkness and emanating light, thats the unique task of <b>‘Jyothi’</b>. <br> <br> Keeping this objective in mind the 
        founding fathers named our society as "Jyothi Co-op. Credit Society".

        <br> <br>
        Started on the 5th day of October, 1981 with only 138 members with the area of operation restricted 
        to Greater Mumbai, We have grown to 5856 members with the working capital of Rs.34.59 crores 
        and area of operation covering Thane & Navi Mumbai districts
      </p>
      <p class="align-center">
        <a class="btn" routerLink="/about-us"> Read more</a>
      </p>
    </div>
  </div>
</section>