<div class="inner-page">

  <div class="row">

    <div class="col-md-10">

      <h2>
          Rate Of Interest Deposits
        </h2>
        <p>(W.E.F. 01.08.2024)</p>


      <table class="table table-bordered table-hover table-sm">
        <thead class="thead-light">
          <tr>
            <th>From</th>
            <th>To</th>
            <th>General</th>
            <th>Sr. Citizen</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>46 Days and above</td>
            <td>less then 90 Days</td>
            <td>4.50%</td>
            <td>4.50%</td>
          </tr>
          <tr>
            <td>91 Days and above</td>
            <td>less then 180 Days</td>
            <td>4.75%</td>
            <td>4.75%</td>
          </tr>
          <tr>
            <td>181 Days and above</td>
            <td>less then 1 Year</td>
            <td>5.25%</td>
            <td>5.75%</td>
          </tr>
          <tr>
            <td>1 Year and above</td>
            <td>Upto 400 days</td>
            <td>7%</td>
            <td>7.50%</td>
          </tr>
          <tr>
            <td>For 401 Days To 500 Days</td>
            <td></td>
            <td>7.50%</td>
            <td>8%</td>
          </tr>
          <tr>
            <td>Above 500 Days </td>
            <td></td>
            <td>7%</td>
            <td>7.50%</td>
          </tr>
        </tbody>
      </table>

    </div>

    

  </div>

  <div class="row">
    <div class="col-md-10" style="text-align: center;">
      <p>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10"  style="text-align: center;">
      <p>
        Saving Bank Account 3% 
      </p>
    </div>
  </div>
</div>