<div class="page">
  <div class="row header">
    <div class="col-md-1"></div>
    <div class="col-md-10 align-center">
      <h1>In the service of Mumbaites</h1>
    </div>
    <div class="col-md-1"></div>
  </div>

  <div class="row content">
    <div class="col-md-1"></div>
    <div class="col-md-10">

      
      <p>
        Dispelling darkness and emanating light, thats the unique task of ‘Jyothi’. Keeping this
        objective in mind the founding fathers named our society as <b>"Jyothi Co-op. Credit
          Society"</b>.
      </p>

      <p>
        The financial assistance provided by the society has helped in ameliorating the
        life of members. The founder members endowed with their selfless nature espoused to
        create an institution to extend a helping hand to those of us who needed it the most. It
        was largely influenced by Maharashtra’s Co-operative culture. As it would happen with
        any new born organization, Jyothi also had its up’s & down’s. But steadfastly its
        founders pursued the noble cause undertaken by them and have stoically achieved what
        many others might have found it difficult and would have given up long back. </p>

      <p>
        <b>Dr. H.M. Subbiah</b> the then President of the erstwhile charitable institution Kulala Sangha Mumbai
        took the lead along with some dedicated youth of the community. The registration of
        the society took place on the <b>5th day of October, 1981</b> in the name of <b>“Jyothi Urban Coop.
          Credit Society”</b> with 138 members registered in the share registry with the area of
        operation restricted to Greater Mumbai, which was extended to Thane District & Navi
        Mumbai in the year 2002.</p>


      <p>It was from scratch that the Society had to start, hence building the confidence of its 
        members was the     primordial task before its members. Its
        very existence being at stake, any wrong step would have resulted in the collapse of the
        whole society. The situation was never conducive, but the founders refused to buckle
        down under pressure. They understood that the endeavour they had embarked upon
        was not a bed of roses but a path of thorns and they accepted the challenge. It was the
        young blood who worked ardently and enabled us to reap the fruits of their toil today.
        Membership expanded along with awareness and saving habits. A dream dared to be
        dreamt by a few visionaries has borne fruit today. As of financial year 31.03.2019
        Society’s financial position is that we have <b>a total Membership Nos 5856 with working
        capital Rs.34.59 crores.</b> We salute the wisdom and foresight of the founding fathers of
        Jyothi Co-op. Credit Society Ltd.


      </p>



    </div>
    <div class="col-md-1"></div>
  </div>
</div>