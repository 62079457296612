import { Component } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './navimumbai-branch-opening.component.html',
  styleUrls: ['./navimumbai-branch-opening.component.scss']
})
export class NavimumbaiBranchOpeningComponent {
  
  
  album: any = [];
  constructor(private _lightbox: Lightbox) {

    this.album.push({ 'src': 'assets/gallery/navi-mumbai-branch-opening/jyothi-co-op-credit-society-image1.jpg', 'caption': 'Image', 'thumb': 'assets/gallery/navi-mumbai-branch-opening/jyothi-co-op-credit-society-image1.jpg' });
    this.album.push({ 'src': 'assets/gallery/navi-mumbai-branch-opening/jyothi-co-op-credit-society-image2.jpg', 'caption': 'Image', 'thumb': 'assets/gallery/navi-mumbai-branch-opening/jyothi-co-op-credit-society-image2.jpg' });
    this.album.push({ 'src': 'assets/gallery/navi-mumbai-branch-opening/jyothi-co-op-credit-society-image3.jpg', 'caption': 'Image', 'thumb': 'assets/gallery/navi-mumbai-branch-opening/jyothi-co-op-credit-society-image3.jpg' });
    this.album.push({ 'src': 'assets/gallery/navi-mumbai-branch-opening/jyothi-co-op-credit-society-image4.jpg', 'caption': 'Image', 'thumb': 'assets/gallery/navi-mumbai-branch-opening/jyothi-co-op-credit-society-image4.jpg' });
    this.album.push({ 'src': 'assets/gallery/navi-mumbai-branch-opening/jyothi-co-op-credit-society-image5.jpg', 'caption': 'Image', 'thumb': 'assets/gallery/navi-mumbai-branch-opening/jyothi-co-op-credit-society-image5.jpg' });
    this.album.push({ 'src': 'assets/gallery/navi-mumbai-branch-opening/jyothi-co-op-credit-society-image6.jpg', 'caption': 'Image', 'thumb': 'assets/gallery/navi-mumbai-branch-opening/jyothi-co-op-credit-society-image6.jpg' });
    this.album.push({ 'src': 'assets/gallery/navi-mumbai-branch-opening/jyothi-co-op-credit-society-image7.jpg', 'caption': 'Image', 'thumb': 'assets/gallery/navi-mumbai-branch-opening/jyothi-co-op-credit-society-image7.jpg' });
    this.album.push({ 'src': 'assets/gallery/navi-mumbai-branch-opening/image8.jpg', 'caption': 'Image', 'thumb': 'assets/gallery/navi-mumbai-branch-opening/image8.jpg' });
    this.album.push({ 'src': 'assets/gallery/navi-mumbai-branch-opening/image9.jpg', 'caption': 'Image', 'thumb': 'assets/gallery/navi-mumbai-branch-opening/image9.jpg' });

  }

  open(index: number): void {
    this._lightbox.open(this.album, index);
  }

  close(): void {
    this._lightbox.close();
  }

}
