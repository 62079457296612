<div class="container-fluid contactus-widget">
  <div class="row">
    <div class="col-md-1">
    </div>

    <div class="col-md-5">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d471.7376760957574!2d72.834324!3d18.9357576!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d1db8d43b511%3A0xc6cf8e0c419e9011!2sKulala%20Sangha%20Mumbai%20-%20Jyothi%20Co-Operative%20Credit%20Society%20Limited!5e0!3m2!1sen!2sin!4v1576751232354!5m2!1sen!2sin"
        width="500" height="300" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
    </div>

    <div class="col-md-5">

      <div class="row">
        <div class="col-md-12">
          <h2 class="align-left">
            Drop a Line
          </h2>
        </div>
      </div>

      <div class="row">
        <div class="col-md-10  align-left">
          <h4>
            Don't hesitate to contact us
          </h4>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div>

            <form #contactForm="ngForm" (ngSubmit)="sendMail(contactForm)">
              <div class="row" *ngIf="mailsent">
                <div data-form-alert="" class="alert alert-success col-md-12">Thanks for filling out the
                  form!
                </div>
                <div data-form-alert-danger="" class="alert alert-danger col-md-12">
                </div>
              </div>

              <div class="row" *ngIf="!mailsent">

                <div class="col-md-6  form-group" data-for="name">
                  <input type="text" name="name" ngModel placeholder="Your Name" data-form-field="Name" required
                    class="form-control" id="name">
                </div>
                <div class="col-md-6  form-group" data-for="phone">
                  <input type="text" name="phone" ngModel placeholder="Phone" data-form-field="Phone" required
                    class="form-control" id="phone">
                </div>
                <div data-for="email" class="col-md-12  form-group">
                  <input type="text" name="email" ngModel placeholder="Email" data-form-field="Email" required email
                    class="form-control" id="email">
                </div>
                <div data-for="message" class="col-md-12  form-group">
                  <textarea name="message" ngModel placeholder="Message" data-form-field="Message" class="form-control"
                    id="message"></textarea>
                </div>
                <div class="col-md-12 align-center">
                  <button type="submit" class="btn btn-form btn-secondary display-4">SEND MESSAGE</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1">
    </div>
  </div>
</div>