import { Component } from '@angular/core';

@Component({
  selector: 'app-jyothi-deposit-widget',
  templateUrl: './recurring-deposit-widget.component.html',
  styleUrls: ['./recurring-deposit-widget.component.scss']
})
export class RecurringDepositWidgetComponent  {

}
