<div class="container-fluid deposits page">

  <div class="row header">
    <div class="col-md-12">
      <h1>Deposits</h1>
    </div>
  </div>

  <div class="row">
    <nav class="col-md-2 bg-light">
      <ul class="nav navbar-nav">


        <li class="nav-item">
          <a class="nav-link active" [routerLink]="['/deposits']">
            Interest Rate
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link " [routerLink]="['jyothi-monthly-income-plan']">
            Monthly Income Plan
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link" [routerLink]="['double-benefit-plan']">
            Double Plan Benefit
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['jyothi-lakhpati-scheme']">
            Lakhpati Scheme
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['recurring-deposit']">
            Recurring Deposit
          </a>
        </li>
      </ul>
    </nav>

    <div class="col-md-10">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>